import { newUser, User } from "gen/models/User"

export type QuoteInternalSalesAggregate = User & {
    QuoteInternalSalesID: number; 
}

export const newQuoteInternalSalesAggregate = () : QuoteInternalSalesAggregate => ({
    ...newUser(), 
    QuoteInternalSalesID: 0, 
})

export const newQuoteInternalSalesAggregateFromUserProfile = (user: User, quoteInternalSalesID: number) : QuoteInternalSalesAggregate => ({
    ...user, 
    QuoteInternalSalesID: quoteInternalSalesID, 
})