import { FormControl, Grid, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Container } from 'core/components/Container';
import { DatePickerButton } from 'core/components/DatePicker';
import moment from 'moment';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { StyledFormControl } from './components';
import { bidTypesAtom, marketsAtom, quoteTypesAtom, selectedQuoteAtom } from './state';

const NewQuoteDetailsForm = () => {

    const [quote, setQuote] = useRecoilState(selectedQuoteAtom);
    const bidTypes = useRecoilValue(bidTypesAtom)
    const quoteTypes = useRecoilValue(quoteTypesAtom)
    const markets = useRecoilValue(marketsAtom); 

    
    const setDueDateFromPicker = (d : Date) => {
        setQuote({
            ...quote, 
            DueDateString: moment(d).format(), 
        }); 
    }

    const setFollowUpDateFromPicker = (d : Date) => {
        setQuote({
            ...quote, 
            FollowUpDateString: moment(d).format(), 
        }); 
    }

    const onDescriptionChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        setQuote({ ...quote, Description: event.target.value as string }); 
    }

    const onQuoteTypeChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setQuote({ ...quote, QuoteTypeID: parseInt(event.target.value as string) }); 
    }

    const onBidTypeChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setQuote({ ...quote, BidTypeID: parseInt(event.target.value as string) }); 
    }

    return (
        <Container>
            <Grid container spacing={3}>
                
                <Grid item md={1}>
                    <FormControl>
                        Due Date: 
                        <DatePickerButton 
                            value={quote.DueDateString} 
                            onChange={setDueDateFromPicker} 
                        /> 
                    </FormControl>
                </Grid>

                <Grid item md={1}>
                    <FormControl>
                        Follow Up Date:
                        <DatePickerButton 
                            value={quote.FollowUpDateString} 
                            onChange={setFollowUpDateFromPicker} 
                        /> 
                    </FormControl>
                </Grid>
                
                <Grid item md={3}>
                    <StyledFormControl>
                        <InputLabel>Job Name</InputLabel>
                        <Input value={quote.Description} onChange={onDescriptionChanged} /> 
                    </StyledFormControl>
                </Grid>
                <Grid item md={2}>
                    Quote Type:<br /> 
                    <Select value={quote.QuoteTypeID} onChange={onQuoteTypeChanged} style={{ width: '100%' }}>
                        <MenuItem key={-1} value={0}>Not Set</MenuItem>
                        {quoteTypes.Items.map((quoteType, key) => 
                            <MenuItem key={key} value={quoteType.ListItemID}>{quoteType.Name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item md={2}>
                    Bid Type:<br /> 
                    <Select value={quote.BidTypeID} onChange={onBidTypeChanged} style={{ width: '100%' }}>
                        <MenuItem key={-1} value={0}>Not Set</MenuItem>
                        {bidTypes.Items.map((bidType, key) => 
                            <MenuItem key={key} value={bidType.ListItemID}>{bidType.Name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item md={2}>
                    Are We Bidding?:<br />
                    <Select value={quote.AreWeBidding} onChange={(e) => setQuote({ ...quote, AreWeBidding: parseInt(e.target.value as string)})} style={{ width: '100%' }}>
                        <MenuItem value={0}>Yes</MenuItem>
                        <MenuItem value={1}>No</MenuItem>
                        <MenuItem value={2}>??</MenuItem>
                    </Select>
                </Grid>
                <Grid item md={2}>
                    Quote Complete?:<br />
                    <Select value={quote.QuoteComplete} onChange={(e) => setQuote({ ...quote, QuoteComplete: parseInt(e.target.value as string)})} style={{ width: '100%' }}>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                    </Select>
                </Grid>
                {/* Market */}
                <Grid item xs={2}>
                    Market<br /> 
                    <Select 
                        value={quote.MarketID} 
                        style={{ width: '100%' }}
                        onChange={(e) => setQuote({
                            ...quote, 
                            MarketID: parseInt(e.target.value as string)
                        })}>
                            <MenuItem key={-1} value={0}>None Set</MenuItem>
                            {markets.Items.map((market, key) => 
                                <MenuItem value={market.ListItemID} key={key}>
                                    {market.Name}
                                </MenuItem>
                            )}
                    </Select>
                </Grid>

            </Grid>
        </Container>
    ); 

}

export default NewQuoteDetailsForm; 