import { Button, CircularProgress, IconButton,  MenuItem, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { newQuoteInternalSalesAggregate, newQuoteInternalSalesAggregateFromUserProfile } from 'app/definitions/aggregates/QuoteInternalSalesAggregate';
import { useGetMyAccountUsers } from 'gen/routes/Account';
import { Container } from 'core/components/Container';
import handleNetworkError from 'core/utils/handleNetworkError';
import React from 'react';
import { Alert } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { selectedQuoteAtom } from './state';

const QuoteInternalSales = () => {

    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 
    const { data: usersData, status: usersStatus, error: usersError } = useGetMyAccountUsers(1, 0, 100); 

    if(usersStatus === "error") {
        return <Alert color="danger">
            {handleNetworkError(usersError)}
        </Alert>
    }

    if(usersStatus === "loading" || !usersData) {
        return <CircularProgress /> 
    }

    const users = usersData.data.Data; 

    const addNewInternalSales = () => {

        setQuote({
            ...quote, 
            InternalSales: [
                ...quote.InternalSales.map(s => ({ ...s})), 
                newQuoteInternalSalesAggregate(),
            ]
        })
    }

    const deleteInternalSales = (idx: number) => () => {

        setQuote({
            ...quote, 
            InternalSales: (quote.InternalSales.length - 1 > 0) ? quote.InternalSales.filter((x, key) => key !== idx).map(x => ({ ...x })) : []
        })
    }
    
    // const onSelectInternalSales = (index : number) => (result: SearchResultDTO) => {
    //     getUserFromID(result.id)
    //         .then(userData => {
    //             setQuote({
    //                 ...quote, 
    //                 InternalSales: Object.assign([...quote.InternalSales], {[index] : newQuoteInternalSalesAggregateFromUserProfile(userData.data.UserProfile, quote.InternalSales[index].QuoteInternalSalesID, quote.InternalSales[index].Commission)})
    //             })
    //         })
    // }

    const onSelectInternalSales = (index: number) => (e) => {
        
        const InternalSalesID = parseInt(e.target.value as string); 
        const InternalSales = users.find(x => x.UserID === InternalSalesID); 

        if(!InternalSales) return; 

        setQuote({
            ...quote, 
            InternalSales: Object.assign([...quote.InternalSales], {[index] : newQuoteInternalSalesAggregateFromUserProfile(InternalSales, quote.InternalSales[index].QuoteInternalSalesID)})
        })

    }

    return (
        <Container title={`InternalSales (${quote.InternalSales.length})`}>
            {quote.InternalSales.map((InternalSales, key) => 
                <Container key={key}>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ flexGrow: 1 }}>
                            <TextField 
                                variant="outlined"
                                size="small"
                                margin="dense"
                                fullWidth
                                label="InternalSales"
                                select
                                value={InternalSales.UserID}
                                onChange={onSelectInternalSales(key)}
                                helperText="Select InternalSales Person"
                            >
                               
                                {users.map((user, key) => 
                                    <MenuItem key={key} value={user.UserID}>
                                        {user.FullName}
                                    </MenuItem>
                                )}
                            </TextField>
                            {/* <Autocomplete
                                title={"Search Manufacturer Products..."}
                                searchFn={searchUsersByName}
                                initialValue={InternalSales.UserID > 0 ? InternalSales.FirstName + " " + InternalSales.LastName : ""}
                                onSelect={onSelectInternalSales(key)}
                            />                                  */}
                        </div>
                        <div style={{ flexGrow: 1 }}>&nbsp;</div>

                        <div>
                            <IconButton onClick={deleteInternalSales(key)}>
                                <Delete /> 
                            </IconButton>
                        </div>
                    </div>
                </Container>
            )}
            <Button variant="contained" onClick={addNewInternalSales}>Add Internal Sales</Button>
        </Container>
    ); 
}

export default QuoteInternalSales; 