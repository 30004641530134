import { Grid, MenuItem, Select, TextField } from '@material-ui/core';
import { Container } from 'core/components/Container';
import { DatePickerButton } from 'core/components/DatePicker';
import MoneyFormat from 'core/components/MoneyFormat';
import { MoneyInput } from 'core/components/MoneyInput';
import moment from 'moment';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GridRow, Label } from './components';
import { bidTypesAtom, marketsAtom, quoteStatusesAtom, quoteTypesAtom, selectedQuoteAtom } from './state';
    
const QuoteDetailsForm = () => {

    const [quote, setQuote] = useRecoilState(selectedQuoteAtom); 
    const quoteStatuses = useRecoilValue(quoteStatusesAtom); 
    const markets = useRecoilValue(marketsAtom); 
    const quoteTypes = useRecoilValue(quoteTypesAtom); 
    const bidTypes = useRecoilValue(bidTypesAtom); 

    const setDueDateFromPicker = (d : Date) => {
        setQuote({
            ...quote, 
            DueDateString: moment(d).format(), 
        }); 
    }

    const setFollowUpDateFromPicker = (d : Date) => {
        setQuote({
            ...quote, 
            FollowUpDateString: moment(d).format(), 
        }); 
    }


    // Gross Margin 
    let grossMargin = (((quote.TotalPrice - quote.ProjectCost) / quote.TotalPrice) * 100); 

    if (isNaN(grossMargin)) {
        grossMargin = 0; 
    } else {
        grossMargin = parseFloat(grossMargin.toFixed(2)); 
    }

    let totalCommission = parseFloat((quote.TotalPrice - quote.ProjectCost).toFixed(2))

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                
                <Container title="Details">

                    <Grid container spacing={1}>
                        
                        <Grid item xs={4} style={{ textAlign: 'right'}}><Label>Quote Number:</Label></Grid>
                        <Grid item xs={8}>{quote.QuoteNumber}</Grid>

                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Created:</Label></Grid>
                        <Grid item xs={8}>{moment(quote.DateCreated).format('L')}</Grid>

                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Job Name:</Label></Grid>
                        <Grid item xs={8}>
                            <TextField fullWidth onChange={(e) => setQuote({ ...quote, Description: e.target.value })} value={quote.Description} label="Job Name" size="small" variant="outlined" /> 
                        </Grid>

                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Due Date:</Label></Grid>
                        <Grid item xs={8}>
                            <DatePickerButton 
                                onChange={(setDueDateFromPicker)} 
                                value={quote.DueDateString} 
                                showTime={true}/> 
                        </Grid>

                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Follow Up Date:</Label></Grid>
                        <Grid item xs={8}>
                            <DatePickerButton 
                                onChange={(setFollowUpDateFromPicker)} 
                                value={quote.FollowUpDateString} /> 
                        </Grid>

                        {/* Quote Type */}
                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Quote Type:</Label></Grid>
                        <Grid item xs={8}>
                            <Select 
                                value={quote.QuoteTypeID} 
                                style={{ width: '100%' }}
                                onChange={(e) => setQuote({
                                    ...quote, 
                                    QuoteTypeID: parseInt(e.target.value as string)
                                })}>
                                    {quoteTypes.Items.map((quoteType, key) => 
                                        <MenuItem value={quoteType.ListItemID} key={key}>
                                            {quoteType.Name}
                                        </MenuItem>
                                    )}
                            </Select>
                        </Grid>

                        {/* Bid Type */}
                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Bid Type:</Label></Grid>
                        <Grid item xs={8}>
                            <Select 
                                value={quote.BidTypeID} 
                                style={{ width: '100%' }}
                                onChange={(e) => setQuote({
                                    ...quote, 
                                    BidTypeID: parseInt(e.target.value as string)
                                })}>
                                    {bidTypes.Items.map((bidType, key) => 
                                        <MenuItem value={bidType.ListItemID} key={key}>
                                            {bidType.Name}
                                        </MenuItem>
                                    )}
                            </Select>
                        </Grid>

                        {/* Market */}
                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Market:</Label></Grid>
                        <Grid item xs={8}>
                            <Select 
                                value={quote.MarketID} 
                                style={{ width: '100%' }}
                                onChange={(e) => setQuote({
                                    ...quote, 
                                    MarketID: parseInt(e.target.value as string)
                                })}>
                                    <MenuItem key={-1} value={0}>None Set</MenuItem>
                                    {markets.Items.map((market, key) => 
                                        <MenuItem value={market.ListItemID} key={key}>
                                            {market.Name}
                                        </MenuItem>
                                    )}
                            </Select>
                        </Grid>
                        
                        {/* Quote Status */}
                        <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Status:</Label></Grid>
                        <Grid item xs={8}>
                            <Select 
                                value={quote.QuoteStatusID} 
                                style={{ width: '100%' }}
                                onChange={(e) => setQuote({
                                    ...quote, 
                                    QuoteStatusID: parseInt(e.target.value as string)
                                })}>
                                    {quoteStatuses.Items.map((quoteStatus, key) => 
                                        <MenuItem value={quoteStatus.ListItemID} key={key}>
                                            {quoteStatus.Name}
                                        </MenuItem>
                                    )}
                            </Select>
                        </Grid>

                        {/* Are We Bidding? */}
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <Label>Are We Bidding?</Label>
                        </Grid>
                        <Grid item xs={8}>
                            <Select 
                                value={quote.AreWeBidding} 
                                style={{ width: '100%' }}
                                onChange={(e) => setQuote({
                                    ...quote, 
                                    AreWeBidding: parseInt(e.target.value as string)
                                })} disabled={quote.JobID !== 0}>
                                    <MenuItem value={0}>Yes</MenuItem>
                                    <MenuItem value={1}>No</MenuItem>
                                    <MenuItem value={2}>??</MenuItem>
                            </Select>
                        </Grid>

                        {/* QuoteComplete? */}
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <Label>Quote Complete?</Label>
                        </Grid>
                        <Grid item xs={8}>
                            <Select 
                                value={quote.QuoteComplete} 
                                style={{ width: '100%' }}
                                onChange={(e) => setQuote({
                                    ...quote, 
                                    QuoteComplete: parseInt(e.target.value as string)
                                })} disabled={quote.JobID !== 0}>
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                            </Select>
                        </Grid>                        


                        {/* Quote Status */}
                        {/* <Grid item xs={4} style={{ textAlign: 'right' }}><Label>Probability:</Label></Grid>
                        <Grid item xs={8}>
                            <Select 
                                value={quote.ProbabilityID} 
                                style={{ width: '100%' }}
                                onChange={(e) => setQuote({
                                    ...quote, 
                                    ProbabilityID: parseInt(e.target.value as string)
                                })}>
                                    <MenuItem value={0} key={-1}>None Set</MenuItem>
                                    {probabilities.Items.map((probability, key) => 
                                        <MenuItem value={probability.ListItemID} key={key}>
                                            {probability.Name}
                                        </MenuItem>
                                    )}
                            </Select>
                        </Grid> */}


                        {/* <Grid item xs={3} style={{ textAlign: 'right' }}><Label>Award Date:</Label></Grid>
                        <Grid item xs={9}>
                            {quote.IsAwardDateSet === 0 ? 
                                <div>
                                    <span>none</span>
                                    <Button onClick={onSetIsAwardDateSet}>Set</Button>
                                </div>
                                :
                                <div>
                                    <DatePicker value={quote.AwardDateString} onChange={onAwardDateChange} /> 
                                </div>
                            }
                        </Grid> */}

                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={4}>
                <Container title="Pricing">
                    <Grid container spacing={1}>
                        
                        <GridRow title="Customer Sell Price:">
                            <MoneyInput value={quote.TotalPrice} onChange={(value) => setQuote({
                                    ...quote,
                                    TotalPrice: value
                                })}
                            /> 
                        </GridRow>

                        <GridRow title="Project Costs:">
                            <MoneyInput value={quote.ProjectCost} onChange={(value) => setQuote({
                                    ...quote,
                                    ProjectCost: value
                                })}
                            /> 
                        </GridRow>

                        <GridRow title="Total Commission:">
                            <div style={{ lineHeight: '65px', verticalAlign: 'middle', fontSize: '14px' }}>
                                $<MoneyFormat val={totalCommission} /> 
                            </div>
                            {/* <Input value={totalCommission} disabled={true} startAdornment={
                                <InputAdornment position="start">$</InputAdornment>
                            } />  */}
                        </GridRow>

                        <GridRow title="Gross Margin:">
                            <div style={{ lineHeight: '65px', verticalAlign: 'middle', fontSize: '14px'}}>
                                {grossMargin}%
                            </div>
                            {/* <Input value={grossMargin} disabled={true} endAdornment={
                                    <InputAdornment position="end">%</InputAdornment>
                                }
                            />  */}
                        </GridRow>
                        
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    )
}

export default QuoteDetailsForm; 