import { CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { downloadQuotesBiddingExcel, downloadQuotesBiddingPDF, useGetBiddingInTheFuture } from 'app/api/quotes';
import NavBarSecondary from 'core/components/NavBarSecondary';
import { DataBody, DataCell, DataHeaderOrdered, DataRow, DataTable, TableNav } from 'core/components/tables';
import handleNetworkError from 'core/utils/handleNetworkError';
import { Perm } from 'core/utils/hasPerm';
import { Quotes_QuotesBiddingInTheFutureDownloadExcelPermission, Quotes_QuotesBiddingInTheFutureDownloadPDFPermission } from 'gen/constants/permissions';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';


const BiddingInTheFutureReport = () => {

    const history = useHistory(); 
    const limit = 50; 
    const [page, setPage] = useState(0); 
    const { data, error, status } = useGetBiddingInTheFuture(page, limit);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null); 

    if(status === "error") {
        return <Alert color="danger">
            {handleNetworkError(error)}
        </Alert>
    }

    if(status === "loading" || !data) {
        return <CircularProgress /> 
    }

    const prev = () => { 
        const newOffset = limit * (page - 1);
        if(newOffset < 0) {
            return; 
        }

        setPage(page - 1)
    }

    const next = () => {
        const newOffset = limit * (page * 1) 
        if(newOffset >= data.data.Count) {
            return 
        }

        setPage(page + 1)
    }

    const areWeBidding = (awb: number): string => {
        switch(awb) {
            case 0: 
                return "yes"; 
            case 1: 
                return "no"; 
            case 2: 
                return "??"; 
            default:
                return "???"; 
        }
    }

    const isQuoteComplete = (awb: number): string => {
        switch(awb) {
            case 1: 
                return "yes"; 
            case 0: 
                return "no"; 
            default:
                return "no"; ; 
        }
    }

    const handleMenuClick = (e) => {
        setMenuAnchorEl(e.currentTarget);
    }

    const doDownloadExcel = () => {
        setMenuAnchorEl(null); 
        downloadQuotesBiddingExcel()
            .then(() => console.log('downloaded!')); 
    }

    const doDownloadPDF = () => {
        setMenuAnchorEl(null); 
        downloadQuotesBiddingPDF()
            .then(() => console.log('downloaded!')); 
    }

    const goto = (url) => {
        history.push(url); 
    }

    return (
        <div style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
            <NavBarSecondary right={
                <div style={{ display: 'flex' }}>

                    <Perm perm={[Quotes_QuotesBiddingInTheFutureDownloadExcelPermission, Quotes_QuotesBiddingInTheFutureDownloadPDFPermission]} any>
                        
                        <IconButton onClick={handleMenuClick}>
                            <CloudDownload /> 
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={() => setMenuAnchorEl(null)}
                        >
                            <Perm perm={Quotes_QuotesBiddingInTheFutureDownloadExcelPermission}>
                                <MenuItem onClick={doDownloadExcel}>
                                    Download Excel
                                </MenuItem>
                            </Perm>

                            <Perm perm={Quotes_QuotesBiddingInTheFutureDownloadPDFPermission}>
                                <MenuItem onClick={doDownloadPDF}>
                                    Download PDF
                                </MenuItem>
                            </Perm> 

                        </Menu>
                    </Perm>


                    <div style={{ display: 'inline-block', height: '48px', paddingTop: '10px', boxSizing: 'border-box'}}>
                        <TableNav {...{ count: data.data.Count, prev, next, page: page, limit: limit }} />
                    </div>
                </div>
            }>
                <strong>Bidding In The Future</strong>
            </NavBarSecondary>
            <div style={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
                <DataTable style={{ height: 'calc(100% - 48px)', overflow: 'hidden', width: '100%' }}>
                
                    <DataRow>
                        <DataHeaderOrdered percent={8}>Date</DataHeaderOrdered>
                        <DataHeaderOrdered percent={13}>Project Name</DataHeaderOrdered>
                        <DataHeaderOrdered percent={5}>Job #</DataHeaderOrdered>
                        <DataHeaderOrdered percent={4}>Bidding ?</DataHeaderOrdered>
                        <DataHeaderOrdered percent={10}>Quote Complete ?</DataHeaderOrdered>
                        <DataHeaderOrdered percent={10}>Engineer</DataHeaderOrdered>
                        <DataHeaderOrdered percent={15}>Salesman</DataHeaderOrdered>
                        <DataHeaderOrdered percent={20}>Notes About The Job</DataHeaderOrdered>
                        <DataHeaderOrdered percent={15}>Bidders</DataHeaderOrdered>
                    </DataRow>

                    <DataBody>
                        {data.data.Data.map((group, k) => 
                            <>
                                <DataRow>
                                    <DataCell color={'#99ccff'} percent={100} align="center">
                                        <strong>{group.Title}</strong>
                                    </DataCell>
                                </DataRow>
                                {group.Rows.map((row, l) => 
                                    <DataRow key={l} onClick={() => goto(`/quotes/${row.QuoteID}`)}>
                                        <DataCell percent={8}>{row.DueDateString}</DataCell>
                                        <DataCell percent={13}>{row.Description}</DataCell>
                                        <DataCell percent={5}>{row.QuoteNumberString}</DataCell>
                                        <DataCell percent={4}>{areWeBidding(row.AreWeBidding)}</DataCell>
                                        <DataCell percent={10}>{isQuoteComplete(row.QuoteComplete)}</DataCell>
                                        <DataCell percent={10}>{row.Engineers}</DataCell>
                                        <DataCell percent={15}>{row.Salesman}</DataCell>
                                        <DataCell percent={20}>{row.Notes}</DataCell>
                                        <DataCell percent={15}>{row.Bidders}</DataCell>
                                    </DataRow>
                                )}
                            </>
                        )}
                    </DataBody>
                
                </DataTable>
            </div>
        </div>
    )
}


export default BiddingInTheFutureReport; 